export default function Privacy() {
  return (
    <div className="container" style={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'start', textAlign: 'left', marginTop: '20px' }}>
         <h1>Privacy Policy</h1>
      <p id="doc">
        Your privacy is important to National Preps, (“we”, “our”, or “us”), and we are
        committed to the protection of your personal information. Our Privacy
        Policy exists to help you understand what information National Preps collects, how
        we collect and use it, and what choices you have. To make sure your
        personal information is secure, we communicate our privacy and security
        guidelines to National Preps employees and strictly enforce privacy safeguards
        within the company. Please read this Privacy Policy before visiting our
        websites, accessing or using our services or products (including our
        websites, platforms, mobile applications, software and other services) or providing any personal information to
        National Preps. If you do not wish to have your information used and disclosed as
        provided in this Privacy Policy, please refrain from providing your
        information and/or delete the information you may have submitted in the
        past. In doing so, please understand that your access to information,
        services and other benefits may be substantially limited. Further
        assistance is available via email to{" "}
        <a href="mailto:admin@nationalpreps.com">admin@nationalpreps.com</a>.
      </p>
      <p>
        If you use National Preps products or services, further information may also be
        provided in your contract with us. If you are an employee, worker or
        contractor of National Preps, the information about how we handle your personal
        information is available in the National Preps Handbook. We do not collect social
        security or equivalent national tax ID numbers, except for employment
        purposes. If you are a prospective employee or job applicant of National Preps, we
        will provide you with information about how we handle your personal
        information for recruitment purposes. This will be provided to you if
        you commence any of our recruitment initiatives.
      </p>
      <p id="one">
        <strong>Collection and Use of Personal Information</strong>
      </p>
      <p>
        Personal information is data relating to a person that can be used to
        help uniquely identify them, either directly or indirectly. We may
        collect personal information from and about you, including information
        that you provide, automatically collected information, and information
        from other sources. You may be asked to provide personal information
        when in contact with National Preps. If you do not provide us with certain
        requested personal information, we may not be able to provide you with
        some or all of National Preps products and services.
      </p>
      <p>
        National Preps may use your personal information to provide and customize our
        products and services, to process your transactions when you place an
        order through our products or services, to provide customer support, to
        communicate with you, to administer and improve our products and
        services, to maintain security and prevent fraud, to meet our legal
        obligations, or otherwise where you direct us or give us consent to use
        your personal information. National Preps may send you periodic marketing and
        promotional emails, push notifications, or text messages to your mobile
        device (about National Preps products or services or third party products or
        services), and we will obtain your consent to do so, where required by
        law. If you no longer wish to receive our marketing and promotional
        communications, you may opt out of receiving them by following the
        opt-out instructions included in any communication that you receive from
        us or by contacting <a href="mailto:admin@nationalpreps.com">admin@nationalpreps.com</a>. Please note that even if you opt
        out of receiving marketing and promotional communications from us, we
        may still send you administrative and transactional messages from which
        you cannot opt out.
      </p>
      <p>
        We may use your personal information for purposes such as auditing, data
        analysis, and research to improve National Preps products, services, and
        customer communications. National Preps and its partners may share your personal
        information and use it as described in this Privacy Policy. Such
        information may be combined with other information to provide and
        improve products, services, content, and advertising. This Privacy
        Policy does not cover and is not intended to restrict what we do with
        information that is aggregated, de-identified, or anonymized, so it is
        no longer associated with an individually identifiable user. Please note
        that we may combine personal information that we collect (as described
        above) with information from other sources and use the combined
        information for the purposes described in this Privacy Policy.
      </p>
      <p id="two">
        <strong>Personal Information We Collect</strong>
      </p>
      <p>
        When you visit our website(s), download or install a software
        application, use one of our services, create a registered user account,
        purchase or register a product (including our National Preps manufactured
        devices), complete a survey, enter a sweepstakes, participate in a
        promotion, attend an event of ours, or download a software update, we
        may collect personal information, including but not limited to: name,
        mailing address, phone number, email address, relationship to athlete
        (for family members), athlete profile information (as described in a
        Section below), contact preferences, health information, purchase
        history, viewing information for National Preps Fans, credit card information and
        payment history.
      </p>
      <p>
        We may also collect such information when you contact us in person (for
        example, at showcases, conferences, workshops, seminars and other events)
        or via telephone, fax, or email or within our services. When you
        send us an email, that address will be recorded automatically for
        purposes of replying to your email. 
      </p>
      <p>
        We may receive and store certain types of website usage information when
        you visit our websites. Website usage information helps us determine how
        visitors arrive, what type of content is most popular and which visitors
        are interested in particular content and advertising. We may collect the
        page visited, the time, the source of the request, the type of browser
        making the request, the preceding page view and other information about
        your engagement with our site and services. We may also collect your IP
        address and your Device Identifier. A Device Identifier is a number
        automatically assigned to the computer, cell phone or tablet used to
        access the Internet. We may associate your Device Identifier with the
        personal information you provide.
      </p>
      
      <p>
        When you browse our website(s), we may collect information about you,
        regardless of whether you have created a registered user account.
        However, if you choose to access our websites anonymously, we may not be
        able to provide you with particular products or services. We collect and
        store personal information if you provide any such personal information,
        register as a user, provide details at an event, complete a survey or
        supply personal details through any other form of correspondence. National Preps
        uses this information to provide products and services, as well as for
        billing, identification, authentication, products and services
        improvement, contact and research purposes. This information may
        include: date, time, computer identity, crash data, device type and
        setting, operating system, language, region, log preferences,
        registration key, license level, license code, license code status, code
        registration date, last validation date, Ethernet address, IP address,
        MAC address, licensed software name and version, OS version, and any
        other information about user actions required to improve, support and
        maintain our software.
      </p>
      <p>
        In some cases, you may provide information to us about other people,
        such as employees, students, team members, family members and athletes.
        For example, if you fill out an Athlete Profile Page (described below)
        or upload or capture game video.
      </p>
      <p>
        We may receive personal information about you from third parties,
        including from licensors of game video, our customers who create
        user-generated content (including Athlete Profile Page information,
        messages, comments, photographs, livestreams, audio recordings, videos
        and text that users of our products and services choose to create or
        capture with or upload to our products and services), our payment
        processors, our partners who assist us in fraud prevention or in
        connection with claims or disputes, our partners with whom we work for
        advertising measurement, attribution, and analytics and from publicly
        available sources, among other sources. When you pay for products and
        services of ours using a credit card or other payment method, your
        credit card or other payment information is collected and processed by
        our third-party payment processors. For example, if you pay with a
        credit card, the payment information that you provide through our
        services is transmitted directly to our payment processor. We may
        receive limited information about your payment card from our payment
        processors. Our payment processors will collect the financial
        information necessary to process your payments in accordance with the
        payment processor’s respective services agreement and privacy policy.
      </p>
      <p id="three">
        <strong>Athlete Profile Pages</strong>
      </p>
      <p>
        These pages may contain and therefore collect: name, home and email
        addresses, phone number, school (name, city and state), graduation year,
        GPA, ACT/SAT scores, transcripts, parent/guardian information (name,
        relation, email and phone), age, birth date, photograph, height, weight,
        jersey number, Twitter handle, sports position, performance scores
        (40-yard dash, agility shuffle, power ball, vertical jump), speed and
        strength information, sports awards, sports videos featuring the
        athlete, and other athlete profile information, as may be updated from
        time to time. This information can be accessed by the athlete, coaches
        and team administrators. The general
        public may have limited access to athlete profile information based on
        administrator selected privacy settings. Home and email addresses, phone
        number, GPA, ACT/SAT scores, transcripts, parent/guardian information
        (name, relation, email and phone), age, birth date, graduation year and
        sports awards are not available to the general public by default.
      </p>
     
      <p id="five">
        <strong>Data Storage</strong>
      </p>
      <p>
        National Preps uses third-party vendors and hosting partners to provide the
        necessary hardware, software, networking, storage and related technology
        required to run the National Preps website, and such third-party vendors may
        collect and store your data on behalf of National Preps. National Preps owns the code,
        databases and all rights therein. National Preps incorporates reasonable
        safeguards to help protect and secure your personal information.
        However, no data transmission over the Internet, wireless transmission,
        or electronic storage of information can be guaranteed to be 100%
        secure. Please note that National Preps cannot ensure or warrant the security of
        any information you transmit via its websites and/or products, and you
        do so at your own risk. The websites are general audience websites.
      </p>
      <p id="six">
        <strong>Disclosures to Third Parties</strong>
      </p>
      <p>
        There are circumstances where we may wish to disclose or are compelled
        to disclose your personal information to third parties. We may disclose
        your personal information to the categories of recipients (and for the
        purposes) listed below.
      </p>
      <ul>
        <li>
          <strong>
            Our affiliates, subsidiaries, branches, and associated offices
          </strong>
          . We may disclose your personal information to our affiliates,
          subsidiaries, branches, and associated offices and other companies
          under common control and ownership for purposes consistent with this
          Privacy Policy and other business and operational purposes.
        </li>
        <li>
          <strong>
            Our agents, representatives, distributors, and independent
            contractors
          </strong>
          . We may disclose your personal information to such third parties
          where related to their functions and services.
        </li>
        <li>
          <strong>Our service providers and third-party partners</strong>. We
          may disclose your personal information to third parties that provide
          services to us or provide features as part of our products or
          services, including: cloud storage services; system hosting services;
          research partners; data security services; fraud prevention; payment
          processing services; delivery services; analytics services; legal
          services; professional services; and other services.
        </li>
        <li>
          <strong>Our organization customers</strong>. We may disclose your
          personal information to our third-party organization customers
          offering streaming and archived content and tickets to National Preps Fans as
          described in this Privacy Policy. You can tell when a third party is
          involved, and we share your personal information related to those
          transactions and involvement with that third party.
        </li>
        <li>
          <strong>Our licensees</strong>. We may disclose your personal
          information to licensees of content where you, our customer’s or
          others have given us permission to share such content.
        </li>
        <li>
          <strong>Our marketing and advertising partners</strong>. We may
          disclose your personal information to our marketing and advertising
          partners, including social media platforms, third-party advertising
          networks, and other parties to assist in serving and measuring our or
          their advertisements, in compliance with applicable law.
        </li>
        <li>
          <strong>Other users of our products and services</strong>. We may
          disclose your personal information to other users of our products and
          services if you choose to make such information public or you select
          sharing features on our products or services. For example, if you
          share highlights publicly on the National Preps website, such highlights will be
          viewable by website visitors.
        </li>
        <li>
          <strong>In connection with legal matters</strong>. We may share your
          personal information when we believe that the disclosure is reasonably
          necessary to comply with applicable laws, regulations, legal
          processes, or legal requests.
        </li>
        <li>
          <strong>
            In connection with potential illegal activity, misuse, threats, or
            other situations
          </strong>
          . We may share your personal information when we believe that the
          disclosure is necessary to investigate, prevent, or take action
          regarding illegal activities, suspected fraud, situations involving
          potential threats to the physical safety of any person, violations of
          our National Preps Site Terms or Acceptable Use Policy, or as otherwise required
          by law.
        </li>
        <li>
          <strong>
            Third parties in connection with a sale or business transaction
          </strong>
          . We may disclose your personal information in the course of following
          transactions involving one or more National Preps entity: joint venture,
          collaboration, financing, sale of assets, merger, reorganization,
          change of legal form, other sale or purchase of assets during the
          normal course of business on a temporary or permanent basis,
          dissolution, or similar event. If another entity acquires us (or any
          subsidiary or affiliated entity) or any of our (or such subsidiary’s
          or affiliate’s) assets, personal information that we have collected
          may be transferred to such entity and its advisors leading up to and
          following the transaction, including as part of due diligence and upon
          closing of the transaction. In addition, if any bankruptcy or
          reorganization proceeding is brought by or against us, personal
          information that we hold may be considered an asset of ours and may be
          sold or transferred to third parties.
        </li>
        <li>
          <strong>Other third parties</strong>. We may disclose your personal
          information to other parties at your direction or with your consent.
          We may also disclose your personal information to other parties if we
          believe it necessary or appropriate either: (a) under applicable law;
          (b) to protect our operations and those of any of our affiliates; (c)
          to protect our rights, privacy, safety or property, and those of
          others; or (d) to allow us to pursue available remedies or limit
          damages that we may sustain.
        </li>
      </ul>
      <p>
        We may disclose your personal information for other reasons that we will
        describe at the time of information collection or prior to sharing your
        information. Any disclosure to third parties will only take place in
        accordance with the applicable law and for the purposes listed. Except
        as otherwise provided by applicable law, National Preps is not responsible for the
        privacy practices of any third parties.
      </p>
      
      
      <p id="nine">
        <strong>Cookies</strong>
      </p>
      <p>
        A “cookie” is a small amount of data that often includes an anonymous
        unique identifier that is sent to your browser from a website and stored
        on your computer’s hard drive. When you access this website, cookies may
        be sent to your web browser and stored on your computer or mobile
        device. We use cookies to record information about your online
        preferences and allow us to tailor our websites to your interests.
        Information supplied by cookies can help us to understand the profile of
        our visitors and help us to provide you with a better user experience.
        It also helps us to recognize when you are signed in to your National Preps
        account and to provide a more personalized experience. Additionally,
        National Preps uses cookies to serve some targeted advertising. To help promote
        National Preps teams and athletes, we sometimes embed content from social media
        and other third-party websites. These may include Hudl, YouTube,
        Twitter, Facebook, and Instagram. As a
        result, when you visit a page containing such content, you may be
        presented with cookies from these websites and these third-party cookies
        may track your use of the National Preps website. National Preps does not control the
        dissemination of these cookies and you should check the relevant third
        party's website for more information.
      </p>
      
      <p id="eleven">
        <strong>Tracking</strong>
      </p>
      <p>
        Some browsers have a “do not track” feature that lets you tell websites
        you do not want to have your online activities tracked. We respond to
        “do not track” signals as required by applicable law.
      </p>
      <p id="twelve">
        <strong>Third Party Websites</strong>
      </p>
      <p>
        Some links on the National Preps websites may redirect you to third-party websites
        and services that we do not operate. We may also offer social sharing
        tools that let you share actions on the National Preps website with other websites
        and vice versa. The privacy practices of these websites and services
        will be governed by their own privacy policies. We encourage you to
        review and understand their privacy policies before disclosing any
        personal information to them.
      </p>
     
    
      <p id="fourteen">
        <strong>Retention of Personal Information</strong>
      </p>
      <p>
        We keep personal information only for as long as necessary to fulfill
        the purposes for which we have collected it as set out in this Privacy
        Policy, including for the purposes of satisfying any legal, accounting,
        or reporting requirements, unless longer retention is required by
        applicable law. We also retain information when necessary to comply with
        contractual and legal obligations, when we have a legitimate business
        interest to do so (such as improving and developing our products and
        services, and enhancing their safety, security and stability), and for
        the exercise or defense of legal claims. We will not retain any of your
        personal information beyond this period and the retention of your
        personal information will be subject to periodic review.
      </p>
      <p>
        To determine the appropriate retention period for personal information,
        we consider the amount, nature and sensitivity of the personal
        information; the potential risk of harm from unauthorized use or
        disclosure of the personal information; the purposes for which we use
        the personal information; whether we can achieve those purposes through
        other means; and the applicable laws that require us to retain
        information for regulatory purposes or permit us to retain the
        information to preserve our legal rights.
      </p>
      <p>
        We may keep an anonymized form of your personal information, which will
        no longer refer to you, for statistical purposes without time limits, to
        the extent that we have a legitimate and lawful interest in doing so.
      </p>
      <p id="fifteen">
        <strong>Confidentiality and Security</strong>
      </p>
      <p>
        National Preps is committed to seeking to safeguard all personal information that
        you provide to us; seeking to ensure that it remains confidential and
        secure; and taking all reasonable steps to ensure that personal privacy
        is respected. All our data is stored in written or electronic form on
        our servers and computers and in various physical locations. We maintain
        reasonable physical, technical and administrative safeguards to protect
        your personal information from misuse, unauthorized access or
        disclosure, and loss or corruption by computer viruses and other sources
        of harm. We limit access to personal information to those staff members,
        joint venture partners, subsidiary companies and third parties who need
        to know that information for the purposes set out in this Privacy
        Policy.
      </p>
      <p id="sixteen">
        <strong>Compliance with Privacy Laws</strong>
      </p>
      <p>
        National Preps complies with the data protection and privacy laws to which it is
        subject. You should satisfy yourself that you are familiar with those
        laws, including any exceptions which may apply under them. You should
        also be aware that privacy laws in various jurisdictions may change from
        time to time. Except to the extent expressly stated otherwise in this
        Privacy Policy, National Preps accepts no obligations with respect to the handling
        of personal information other than those mandated by law in the country
        which has or countries which have jurisdiction over National Preps in any given
        circumstances.
      </p>
      <p id="seventeen">
        <strong>Grounds for Processing in the UK/EEA</strong>
      </p>
      <p>
        If you are in the United Kingdom and/or European Economic Area, to
        process your data lawfully we need to rely on one or more valid legal
        grounds. The grounds we may rely upon include: (1) your consent to
        particular processing activities. For example, where you have consented
        to us using your information for marketing purposes; (2) our legitimate
        interests as a business (except where your interests or fundamental
        rights override these). For example, it is within our legitimate
        interests to use your data to send you our electronic newsletter and
        product updates if you represent a corporate entity (unless you have
        unsubscribed); (3) our compliance with a legal obligation to which we
        are subject. For example, we have a duty to investigate and respond to
        complaints made against us and may need to process your personal
        information as part of such investigation; or (4) if you are a customer,
        or are representing a customer, because processing your personal data is
        necessary for the performance of a contract.
      </p>
      <p id="eighteen">
        <strong>Your European Data Protection Rights</strong>
      </p>
      <p>
        Please contact us at{" "}
        <a href="mailto:admin@nationalpreps.com">admin@nationalpreps.com</a> any time to exercise
        any of your data protection rights. Data protection law applicable to
        individuals in Europe provides individuals with certain rights,
        including the right to access, rectify, withdraw consent, erase,
        restrict, transport, and object to the processing of their personal
        information. Individuals also have the right to lodge a complaint with
        the relevant information protection authority if they believe that their
        personal information is not being processed in accordance with the law.
        Further information about your rights is set out below:
      </p>
      <p>
        <strong>Right to obtain a copy of your personal information. </strong>
        You may have the right to obtain a copy of the personal information we
        hold about you.
      </p>
      <p>
        <strong>Right to rectification. </strong>You may request that we rectify
        any inaccurate and/or complete any incomplete personal information. If
        we disagree and believe the information to be accurate and complete, we
        will advise you and include a notation on the record that you dispute
        the information’s accuracy. We will respond to your request to correct
        or supplement your personal information within a reasonable time period
        and, in any event, within any time period specified in relevant laws.
      </p>
      <p>
        <strong>Right to withdraw consent</strong>. You may, as permitted by
        law, withdraw your consent to the processing of your personal
        information at any time. Such withdrawal will not affect the lawfulness
        of processing based on your previous consent. Please note that if you
        withdraw your consent, you may not be able to benefit from certain
        service features for which the processing of your personal information
        is essential.
      </p>
      <p>
        <strong>Right to object to processing. </strong>You may, as permitted by
        law, request that we stop processing your personal information. You also
        have the right to ask us not to process your personal data for marketing
        purposes.
      </p>
      <p>
        <strong>Right to erasure. </strong>You may request that we erase your
        personal information and we will comply, unless there is a lawful reason
        for not doing so.
      </p>
      <p>
        <strong>
          Your right to lodge a complaint with the supervisory authority
        </strong>
        We suggest that you contact us about any questions or if you have a
        complaint in relation to how we process your personal information.
        However, you do have the right to contact the relevant supervisory
        authority in the relevant country directly. 
      </p>
     
      <p>
        Individuals in the European Union and the UK should be aware that National Preps
        may transfer your personal data to a third party in countries outside
        the EU or the UK for further processing in accordance with the purposes
        set out in this Privacy Policy. In particular, your personal data may be
        transferred throughout the National Preps group and to our outsourced service
        providers located abroad. In these circumstances, we will, as required
        by applicable law, ensure that your privacy rights are adequately
        protected by appropriate technical, organization, contractual or other
        lawful means.
      </p>
      <p>
        All data that National Preps sends out of the EU/EEA/UK is sent to countries with
        an EU or UK adequacy status, as applicable, or to organisations with
        signed EU Standard Contract Clauses or UK Standard Contractual Clauses.
        National Preps transfers data between its group of companies, for which the
        international transfers of EU/EEA/UK data are detailed within National Preps
        Intra-Group Data Transfer Agreement, which itself includes the required
        EU Standard Contract Clauses and UK Standard Contractual Clauses.
      </p>
      <p>
        All National Preps data transfers to the US are TLS encrypted and generally
        consist of insensitive personal data. National Preps therefore believes the risk
        of government surveillance to the privacy of transferred personal data
        to be very low. Additionally, National Preps will only provide personal data to
        applicable law enforcement authorities when under strict legal
        compulsion. When permitted by applicable law, law enforcement and
        exigencies of time, National Preps will notify its customers of any such legal
        requests involving their personal data.
      </p>
      
      <p>
        Individuals in the UK, European Economic Area, Switzerland or Singapore
        with inquiries or complaints regarding this Privacy Policy should first
        contact National Preps at <a href="mailto:admin@nationalpreps.com">admin@nationalpreps.com</a>.
      </p>
      
      <p>
        National Preps may periodically update this Privacy Policy. The most current
        version of the Privacy Policy will govern our use of your information
        and will always be available at http://www.nationalpreps.io/privacy. By using
        our websites, services, and products, you acknowledge your agreement to
        the terms of this Privacy Policy. We will notify you about material
        changes in the way we treat personal information by sending a notice to
        the email address specified in your membership account or by placing a
        prominent notice on our website.
      </p>
    </div>
  );
}
