import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { supabaseClient } from './services/supabaseclient';

import AdminLayout from './layouts/AdminLayout';
import EndUserLayout from './layouts/EndUserLayout';
import Login from './components/authentication/Login';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import DashboardPage from './pages/DashboardPage';
import PortalPage from './pages/PortalPage';
import AthletePage from './pages/RecruitPage';
import Teams from './components/admin/Teams';

import LeftNav from './components/navigation/LeftNav';
import AthleteView from './components/athletes/AthleteView';
import VerifyEmail from './components/admin/VerifyEmail';


import Integrations from './components/imports/Integrations';
import Imports from './components/imports/Imports';
import Users from './components/admin/Users';
import AdminPage from './pages/AdminPage';
import Athletes from './components/admin/Athletes';
import AthleteEdit from './components/athletes/AthleteEdit';
import AthleteAdd from './components/athletes/AthleteAdd';

import HighSchools from './components/admin/HighSchools';

import ReportHighSchools from './components/reports/HighSchools';
import DailySummary from './components/reports/DailySummary';

import { FavoriteList } from './components/favorite/FavoriteList';

import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';


import { getUserRoles } from './services/supabase';
import Tweets from './components/admin/Tweets';
import ScrapeTweets from './components/imports/ScrapeTweets';
import ImportSocialPosts from './components/imports/importSocialPosts';

LicenseInfo.setLicenseKey('e5231a0327663138814e3ecb0fe3c177Tz03Nzc4MCxFPTE3MzA0MDg4NDMwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

const queryClient = new QueryClient();


function App() {
  const [session, setSession] = useState(null);
  const [role, setRole] = useState('');

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabaseClient.auth.getSession();
      if (session) {
        const role = await getUserRoles(session.user.id);
        setSession(session);
        if (role && role.length > 0) {
          setRole(role[0])
        }
        else {
          setRole(null);
        }

      }
    };

    getSession();

    const { data: { subscription } } = supabaseClient.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    !session ? (

      <Login />

    ) : (

      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>


            {role && role.User_Role?.type === 'A' && (
              <>
                <Route
                  path="/admin/imports"
                  element={<AdminLayout content={<Imports />} header="Manage Imports" session={session} role={role} />}
                />
                <Route
                  path="/admin/users"
                  element={<AdminLayout content={<Users />} header="Manage Users" session={session} role={role} />}
                />

                <Route
                  path="/admin/scrapetweets"
                  element={<AdminLayout content={<ScrapeTweets />} header="Scrape Tweets" session={session} role={role} />}
                />

                <Route
                  path="/admin/processposts"
                  element={<AdminLayout content={<ImportSocialPosts />} header="Process Social Posts" session={session} role={role} />}
                />

                <Route
                  path="/admin/highschools"
                  element={<AdminLayout content={<HighSchools />} header="Manage Schools" session={session} role={role} />}
                />

                <Route
                  path="/admin/verify"
                  element={<AdminLayout content={<VerifyEmail />} header="Manage Users" session={session} role={role} />}
                />
                <Route
                  path="/admin/athletes"
                  element={<AdminLayout content={<Athletes />} header="Manage Athletes" session={session} role={role} />}
                />
             
                <Route
                  path="/admin"
                  element={<AdminLayout content={<AdminPage />} header="Admin" session={session} role={role} />}
                />
                <Route
                  path="/admin/athlete/:id"
                  element={<AdminLayout content={<AthleteEdit />} header="Manage Athlete" session={session} role={role} />}
                />

                <Route
                  path="/admin/athlete/add"
                  element={<AdminLayout content={<AthleteAdd />} header="Manage Athlete" session={session} role={role} />}
                />

                <Route
                  path="/admin/integrations"
                  element={<AdminLayout content={<Integrations />} header="Manage Integrations" session={session} role={role} />}
                />
                <Route
                  path="/admin/tweets"
                  element={<AdminLayout leftNav={<LeftNav />} content={<Tweets />} header="Manage Social Posts" session={session} role={role} />}
                />
                <Route
                  path="/admin/teams"
                  element={<AdminLayout content={<Teams />} header="Manage Teams" session={session} role={role} />}
                />
              </>
            )}

<Route
              path="/privacy"
              element={<EndUserLayout content={<Privacy role={role} />} session={session} role={role} />}
            />
            <Route
              path="/terms"
              element={<EndUserLayout content={<Terms role={role} />} session={session} role={role} />}
            />
            <Route
              path="/"
              element={<EndUserLayout content={<DailySummary role={role} />} session={session} role={role} />}
            />
            <Route
              path="/recruit/athlete"
              element={<EndUserLayout title="HS Athletes" content={<AthletePage role={role} />} session={session} role={role} />}
            />
            <Route
              path="/recruit/portal"
              element={<EndUserLayout title="Transfers" content={<PortalPage />} session={session} role={role} />}
            />
            <Route
              path="/recruit/favorite"
              element={<EndUserLayout content={<FavoriteList />} session={session} role={role} />}
            />
            <Route
              path="/recruit/view/:id"
              element={<EndUserLayout showNav={false} title="Athlete View" content={<AthleteView />} session={session} role={role} />}
            />
            <Route
              path="/reports/dailysummary"
              element={<EndUserLayout content={<DailySummary role={role} />} session={session} role={role} />}
            />
            <Route
              path="/reports/highschools"
              element={<EndUserLayout content={<ReportHighSchools />} session={session} role={role} />}
            />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </QueryClientProvider>

    )
  );
}

export default App;
