import React, { useState, useEffect } from "react";
import { getRecruit} from "../../services/supabase";
import { getRecruitActivitiesWithTeam } from "../../services/supabase";
import { useParams } from 'react-router-dom';
import fallbackImage from '../../assets/imgs/np.png';
import 'react-image-lightbox/style.css'; 
import AthleteFiles from "./AthleteFiles";
import instagramIcon from '../../assets/icons/instagram.svg';
import hudlIcon from '../../assets/icons/hudl-icon.png';
import twitterIcon from '../../assets/icons/twitter-x.svg';
import emailIcon from '../../assets/imgs/email.jpg';
import npLogo from '../../assets/imgs/np.png';


function CommittedStatus({ recruit }) {
    const committedOffers = recruit.Activity?.filter(x => x.interactionTypeId === 4);
    const uniqueOffers = [];
    const seenNames = new Set();

    if (committedOffers && committedOffers.length > 0) {
        for (const offer of committedOffers) {
            if (!seenNames.has(offer.name)) {
                seenNames.add(offer.name);
                uniqueOffers.push(offer);
            }
        }
    }

    return (
        <div>

            {uniqueOffers.length > 0 ? (
                <>
                    <h1 style={{ fontSize: '1.5rem', color: '#222' }}>
                        <strong>Committed</strong>
                    </h1>
                    {uniqueOffers.map((offer, index) => (
                        offer.logo ? (
                            <img
                                key={index}
                                src={offer.logo}
                                alt={offer.teamName}
                                width="75"
                                title={offer.teamName}
                                style={{ marginRight: '10px' }}
                            />
                        ) : (
                            <span key={index}>{offer.name}</span>
                        )
                    ))
                    }
                </>
            ) : (
                null
            )}
        </div>
    );
}

export default function AthleteView(props) {
    const { id } = useParams();
    const [recruit, setRecruit] = useState({});
   
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [activities, setActivities] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [recruitData, filesData] = await Promise.all([
                    getRecruit(id),
                ]);
                recruitData ? setRecruit(recruitData) : setRecruit({});

                const activities = await getRecruitActivitiesWithTeam(id);
                setActivities(activities);
                console.log('a', activities);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className='w-100'>
            <div className='container'>
                <div className="row w-100 d-flex" style={{ backgroundColor: '#222222', color: 'white' }}>
                    <div className="col-md-2 col-sm-12" style={{ textAlign: 'center', alignSelf: 'center' }}>
                        {recruit.image ? <img src={recruit.image} alt={`${recruit.firstName} ${recruit.lastName}`} width='180px' /> : <img width='100px' src={fallbackImage} alt={`${recruit.firstName} ${recruit.lastName}`} />}
                    </div>
                    <div className="col-md-8 col-sm-12 p-5">
                        <h1 style={{ fontSize: '40px' }}><strong>{recruit.firstName?.toUpperCase()} {recruit.lastName?.toUpperCase()}</strong>
                            <br /><span style={{ fontSize: '20px' }}>{recruit.highSchool}</span></h1>
                    </div>
                    <div className="col-md-2 col-sm-12 p-5" >
                        <h1 style={{ fontSize: '40px' }}><strong>{recruit.year}</strong></h1>
                    </div>
                </div>
            </div>
            <div className='container' >
                <div className='row w-100 p-5' style={{ backgroundColor: '#fff' }}>
                    <div className='col-md-2 col-sm-12'>
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Connect</strong></h2>
                        <span>
                            {recruit.twitter && recruit.twitter !== 'Unknown' ? <a target="_blank" rel="noreferrer" href={`https://twitter.com/${recruit.twitter}`}><img src={twitterIcon} style={{ marginRight: '15px' }} /></a> : ''}
                            {recruit.instagram && recruit.instagram !== '' ? <a target="_blank" rel="noreferrer" href={`https://instagram.com/${recruit.instagram}`} alt="Instagram"><img src={instagramIcon} style={{ marginRight: '15px' }} /></a> : ''}
                            {recruit.hudl && recruit.hudl !== '' ? <a target="_blank" rel="noreferrer" href={`${recruit.hudl}`}><img style={{ width: '20px', marginRight: '15px' }} src={hudlIcon} alt="HUDL"></img></a> : ''}
                            {recruit.threads && recruit.threads !== '' ? <a target="_blank" rel="noreferrer" href={`${recruit.threads}`}><img src={npLogo} style={{ marginRight: '15px', width:'25px' }} /></a> : ''}
                            
                            {recruit.email && recruit.email !== '' ? <a target="_blank" rel="noreferrer" href={`mailto:${recruit.email}`}><img style={{ width: '20px', marginRight: '15px' }} src={emailIcon} alt="email"></img></a> : ''}

                        </span>
                    </div>
                    <div className='col-md-1 col-sm-12'>
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Jersey</strong></h2>
                        <p> #{recruit.jersey}</p>
                    </div>
                    <div className='col-md-1 col-sm-12'>
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Height</strong></h2>
                        <p> {recruit.height}</p>
                    </div>

                    <div className='col-md-1 col-sm-12'>
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Weight</strong></h2>
                        <p>
                            {recruit.weight}
                        </p>
                    </div>

                    <div className='col-md-2 col-sm-12'>

                        {recruit.offPosition && (
                            <div>
                                <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Offense Position</strong></h2>
                                <p>{recruit.offPosition}</p>
                            </div>
                        )}
                        {recruit.defPosition && (
                            <div>
                                <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Defense Position</strong></h2>
                                <p>{recruit.defPosition}</p>
                            </div>
                        )}
                        {recruit.specialTeamsPosition && (
                            <div>
                                <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Spec Teams Position</strong></h2>
                                <p>{recruit.specialTeamsPosition}</p>
                            </div>
                        )}

                    </div>
                    <div className='col-md-2 col-sm-12'>
                        {recruit.scoutPos && (
                            <div>
                                <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Scout Position</strong></h2>
                                <p>{recruit.scoutPos}</p>
                            </div>
                        )}
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>NP Rating</strong></h2>
                        <p> {recruit.rank}</p>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row w-100 p-5' style={{ backgroundColor: '#fff', borderTop: '1px solid #ccc' }}>
                    <div className="col-md-3 col-sm-12">
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>High School</strong></h2>
                        <p>
                            {recruit.highSchool}<br />
                            {recruit.hsStreet}<br />
                            {recruit.hsCity}, {recruit.hsState} {recruit.hsZip}<br />
                            {recruit.hsPhone ? `(o) ${recruit.hsPhone}` : null}<br />
                            {recruit.hsCounty} County
                        </p>
                    </div>

                    <div className="col-md-3 col-sm-12">
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Athlete Information</strong></h2>
                        {(recruit.athleteAddress || recruit.athleteCity || recruit.athleteState || recruit.athleteZip || recruit.cellPhone || recruit.homePhone) ? (
                            <div>
                                <p>
                                    {recruit.athleteAddress && <>{recruit.athleteAddress}<br /></>}
                                    {recruit.athleteCity && recruit.athleteState && recruit.athleteZip && (
                                        <>{recruit.athleteCity}, {recruit.athleteState} {recruit.athleteZip}<br /></>
                                    )}
                                    {recruit.cellPhone && <>(c) {recruit.cellPhone}<br /></>}
                                    {recruit.homePhone && <>(h) {recruit.homePhone}<br /></>}
                                    {recruit.twitter && recruit.twitter !== 'Unknown' && (
                                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/${recruit.twitter}`}>
                                            <img src={twitterIcon} style={{ marginRight: '15px' }} alt="Twitter" />
                                        </a>
                                    )}
                                    {recruit.instagram && (
                                        <a target="_blank" rel="noreferrer" href={`https://instagram.com/${recruit.instagram}`} alt="Instagram">
                                            <img src={instagramIcon} style={{ marginRight: '15px' }} alt="Instagram" />
                                        </a>
                                    )}
                                    {recruit.hudl && (
                                        <a target="_blank" rel="noreferrer" href={recruit.hudl}>
                                            <img style={{ width: '20px', marginRight: '15px' }} src={hudlIcon} alt="HUDL" />
                                        </a>
                                    )}
                                    {recruit.email && (
                                        <a target="_blank" rel="noreferrer" href={`mailto:${recruit.email}`}>
                                            <img style={{ width: '20px', marginRight: '15px' }} src={emailIcon} alt="Email" />
                                        </a>
                                    )}
                                </p>
                            </div>
                        ) : (
                            <p>No address information available</p>
                        )}
                    </div>


                    <div className="col-md-3 col-sm-12">
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Coach Information</strong></h2>
                        <div style={{ textAlign: 'left' }}>
                            <p>
                                {recruit.coachname && <>{recruit.coachname}<br /></>}
                                {recruit.coachEmail && recruit.coachEmail !== '' && (
                                    <>
                                        <a target="_blank" rel="noreferrer" href={`mailto:${recruit.coachEmail}`}>
                                            <img style={{ width: '20px', marginRight: '15px' }} src={emailIcon} alt="email" />
                                        </a>
                                        {recruit.coachEmail}
                                        <br />
                                    </>
                                )}
                                {recruit.coachTwitter && recruit.coachTwitter !== 'Unknown' && (
                                    <>
                                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/${recruit.coachTwitter}`}>
                                            <img src={twitterIcon} style={{ marginRight: '15px' }} alt="twitter" />
                                        </a>
                                        {recruit.coachTwitter}
                                    </>
                                )}
                            </p>
                            {recruit.coachPhone && <p>(c) {recruit.coachPhone}</p>}
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-12">
                        <h2 style={{ fontSize: '1.2rem', color: '#222' }}><strong>Academics</strong></h2>
                        <div style={{ textAlign: 'left' }}>
                            <p>
                                {recruit.gpa && (
                                    <div style={{ textAlign: 'left', width: '30%' }}>
                                        <span><strong>GPA</strong></span>: {recruit.gpa}
                                    </div>
                                )}
                            </p>
                            <p>{recruit.sat && (
                                <div style={{ textAlign: 'left', width: '30%' }}>
                                    <span ><strong>SAT</strong></span>: {recruit.sat}
                                </div>
                            )}
                            </p>
                            <p>
                                {recruit.act && (
                                    <div style={{ textAlign: 'left', width: '30%' }}>
                                        <span><strong>ACT</strong></span>: {recruit.act}
                                    </div>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className="row w-100 p-5" style={{ backgroundColor: '#fff', borderTop: '1px solid #ccc' }}>
                    <div className="col-md-9">
                        <div>
                            <h1 style={{ fontSize: '1.5rem', color: '#222' }}><strong>Offers</strong></h1>
                            {activities?.filter(x => x.interactionTypeId === 2).map((offer) => (
                                offer.Team?.logo ? <img src={offer.Team?.logo} alt={offer.Team?.name} width="75" title={offer.Team?.name} style={{ marginRight: '10px' }} /> : offer.Team?.name
                            ))}

                        </div>
                    </div>
                    <div className="col-md-3">
                        <CommittedStatus recruit={recruit} />
                    </div>
                </div>
            </div>


            <div className='container'>
                <div className="row w-100 p-5" style={{ backgroundColor: '#fff', borderTop: '1px solid #ccc' }}>
                    <div className='ml-30 w-100' >
                        <h1 style={{ fontSize: '1.5rem', color: '#222' }}><strong>NP Scout Notes</strong></h1>
                        <p>{recruit.scoutComments}</p>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className="row w-100 p-5" style={{ backgroundColor: '#fff', borderTop: '1px solid #ccc' }}>
                    <div className='ml-30 w-100 d-flex' style={{ flexDirection: "column" }}>
                        <h1 style={{ fontSize: '1.5rem', color: '#222' }}><strong>Images, Videos and Files</strong></h1>
                        <AthleteFiles id={recruit.id} />

                    </div>
                </div>
            </div>

        </div>
    );
}
