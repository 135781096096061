export default function Terms() {
  return (
    <div className="container" style={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'start', textAlign: 'left', marginTop: '20px' }}>
        <h1>Terms and Conditions</h1>

      <p>
        Welcome to the National Preps website. These National Preps Site Terms
        apply to the National Preps website located at www.nationalpreps.com, and
        all associated sites and applications owned by National Preps and its
        affiliates (collectively, the “<strong>National Preps Site</strong>”).
       
        <strong>National Preps</strong> provides the National Preps Site to
        you subject to the following terms of use (“<strong>Site Terms</strong>
        ”).
        <strong>
          By visiting the National Preps Site, you accept the Site Terms
        </strong>
        . Please read them carefully. In addition, when you use any current or
        future National Preps products, services, applications, content, or
        other materials, you will also be subject to the applicable signed
        agreement with National Preps governing your use of National Preps
        services and products (the “<strong>National Preps Agreement</strong>”).
      </p>
      <p>
        1. <strong>PRIVACY</strong>
      </p>
      <p>
        Please review{" "}
        <a
          class="font-bold underline"
          href="/privacy"
        >
          National Preps Privacy Policy
        </a>
        , which also governs your visit to the National Preps Site, to
        understand National Preps practices with respect to your personal
        information.
      </p>
      <p>
        2. <strong>ELECTRONIC COMMUNICATIONS</strong>
      </p>
      <p>
        When you visit the National Preps Site or send emails, feedback, or
        chats to National Preps, you are communicating with National Preps
        electronically. You consent to receive communications from National
        Preps electronically. National Preps will communicate with you by email
        or by posting notices on the National Preps Site. You agree that all
        agreements, notices, disclosures, and other communications that we
        provide to you electronically satisfy any legal requirement that such
        communications be in writing. Any feedback you provide at this National
        Preps Site will be deemed non-confidential. National Preps will be free
        to use such feedback on an unrestricted basis.
      </p>
      <p>
        3. <strong>National Preps INTELLECTUAL PROPERTY</strong>
      </p>
      <p>
        The National Preps Site and the National Preps products and services
        available on or through the National Preps Site, as well as all content
        included on the National Preps Site, such as text, graphics, logos,
        button icons, images, audio clips, video, digital downloads, data
        compilations, and software, (together, “
        <strong>National Preps Intellectual Property</strong>”) are the property
        of National Preps or its content providers and is protected by United
        States and international copyright, trademark, patent, trade secret and
        other intellectual property laws. Without limiting the foregoing, National Preps, the National Preps logo, and all
        related names, logos, product and service names, designs, and slogans
        are trademarks of National Preps, protected under U.S. and international
        law, and may not be used without National Preps prior written
        permission. Other parties’ trademarks used, depicted, or identified on
        the National Preps Site are the property of their respective owners,
        used here by permission, and may be registered in one or more countries.
        Use on the National Preps Site of the trademark(s) (including, but not
        limited to, names and logos) of any other party is not intended to imply
        National Preps affiliation with or endorsement of that party, or that
        party’s sponsorship or endorsement of National Preps and their products
        or services.
      </p>
      <p>
        4. <strong>LICENSE AND SITE ACCESS&nbsp;</strong>
      </p>
      <p>
        4.1. National Preps grants you a limited license to access and make
        personal use of the National Preps Site and not to download (other than
        page caching) or modify it, or any portion of it, except with express
        written consent of National Preps. This license does not include any
        resale or commercial use of the National Preps Site or its contents; any
        derivative use of the National Preps Site or its contents; any
        downloading or copying of account information; or any use of data
        mining, robots, or similar data gathering and extraction tools. Unless
        otherwise specified by National Preps in a separate license, your right
        to use any software, data, documentation, or other materials that you
        access or download through the National Preps Site is subject to these
        Site Terms or, if you have a National Preps account, the applicable
        National Preps Agreement.
      </p>
      <p>
        4.2. The National Preps Site or any portion of the National Preps Site
        may not be reproduced, duplicated, copied, sold, resold, visited, or
        otherwise exploited for any commercial purpose without express written
        consent of National Preps. You may not frame or utilize framing
        techniques to enclose any trademark, logo, or other proprietary
        information (including images, text, page layout, or form) of National
        Preps without express written consent. You may not use any meta tags or
        any other “hidden text” utilizing National Preps name or trademarks
        without the express written consent of National Preps. Any unauthorized
        use terminates the permission or license granted by National Preps. You
        are granted a limited, revocable, and nonexclusive right to create a
        hyperlink to the home page of the National Preps Site, so long as the
        link does not portray National Preps, or its products or services in a
        false, misleading, derogatory, or otherwise offensive matter. You may
        not use any National Preps logo or other proprietary graphic or
        trademark as part of the link without express written permission of
        National Preps.
      </p>
      <p>
        4.3. National Preps reserves the right to withdraw or amend the National
        Preps Site, and any service or material we provide on the National Preps
        Site, in National Preps sole discretion without notice. National Preps
        will not be liable if for any reason all or any part of the National
        Preps Site is unavailable at any time or for any period. From time to
        time, National Preps may restrict access to some parts of the National
        Preps Site, or the entire National Preps Site, to users, including
        registered users. You may not decompile, reverse engineer, disassemble,
        rent, lease, loan, sell, sublicense, or create derivative works of the
        National Preps Site or any portion of the National Preps Site.
      </p>
      <p>
        4.4. If you print, copy, modify, download, or otherwise use or provide
        any other person with access to any part of the National Preps Site in
        breach of the Site Terms, your right to use the National Preps Site will
        stop immediately and you must, at National Preps option, return or
        destroy any copies of the materials you have made. No right, title, or
        interest in or to the National Preps Site or any content on the National
        Preps Site is transferred to you, and all rights not expressly granted
        are reserved by National Preps (or its licensors). Any use of the
        National Preps Site not expressly permitted by these Site Terms is a
        breach of these Site Terms and may violate copyright, trademark, and
        other laws.
      </p>
     
      <p>
        5. <strong>LINKS TO OTHER SITES</strong>
      </p>
      <p>
        The National Preps Site contains links to web sites not operated or
        maintained by National Preps. These links are provided solely as a
        convenience to you and not as an endorsement by National Preps of the
        contents of such third-party web sites. National Preps is not
        responsible for the content of the sites of others and makes no
        representation regarding the content or accuracy of materials on such
        sites. If you decide to access linked third-party web sites, you do so
        at your own risk, and waive any and all claims against National Preps
        regarding the inclusion of links to outside web sites or your use of
        those web sites. 
      </p>
      <p>
        6. <strong>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</strong>
      </p>
      <p>
        THE National Preps SITE AND ALL INFORMATION, CONTENT, MATERIALS,
        PRODUCTS (INCLUDING ANY SOFTWARE) AND SERVICES INCLUDED ON OR OTHERWISE
        MADE AVAILABLE TO YOU THROUGH THE National Preps SITE ARE PROVIDED BY
        National Preps ON AN “AS IS” AND “AS AVAILABLE” BASIS. National Preps
        MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
        AS TO THE OPERATION OF THIS National Preps SITE OR THE INFORMATION,
        CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) OR SERVICES
        INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE National
        Preps SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE
        THAT YOUR USE OF THE National Preps SITE IS AT YOUR SOLE RISK. TO THE
        FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, National Preps DISCLAIMS
        ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
        IMPLIED WARRANTIES OF MERCHANTABILITY, NON- INFRINGEMENT AND FITNESS FOR
        A PARTICULAR PURPOSE. National Preps DOES NOT WARRANT THAT THIS National
        Preps SITE; INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY
        SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
        THROUGH THE National Preps SITE; ITS SERVERS; OR E- MAIL SENT FROM
        National Preps ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. National
        Preps WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
        USE OF THE National Preps SITE OR FROM ANY INFORMATION, CONTENT,
        MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR
        OTHERWISE MADE AVAILABLE TO YOU THROUGH THE National Preps SITE,
        INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
        AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED. CERTAIN STATE
        LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
        LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL
        OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO
        YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
      </p>
      <p>
        7. <strong>APPLICABLE LAW</strong>
      </p>
      <p>
        By visiting the National Preps Site, you agree that the laws of the
        state of Minnesota, without regard to principles of conflict of laws and
        not including any provisions of the 1980 United Nations Convention on
        Contracts for the International Sale of Goods, will govern these Site
        Terms and any dispute of any sort that may arise between you and
        National Preps.
      </p>
      <p>
        8. <strong>DISPUTES&nbsp;</strong>
      </p>
      <p>
        Any dispute relating in any way to your visit to the National Preps Site
        or to services provided by National Preps or through the National Preps
        Site shall be adjudicated exclusively in the District Courts of the
        State of Minnesota in Hennepin or the Federal District Court of
        the District of Minnesota (as permitted by law) and each party agrees not
        to contest the personal jurisdiction of these courts. Notwithstanding
        the foregoing, National Preps shall have the right to commence and
        prosecute any legal or equitable action or proceeding before any
        non-U.S. court of competent jurisdiction to obtain injunctive or other
        relief.
      </p>
      <p>
        9. <strong>NCAA REGULATIONS/OTHER REGULATIONS&nbsp;</strong>
      </p>
      <p>
        National Preps is in no way affiliated with or sponsored by the NCAA.
        You are responsible for your own activities in connection with the
        National Preps Site, including your use of any National Preps products
        or services. Accordingly, you are responsible for knowing and complying
        with the NCAA’s rules, regulations, and laws or other similar rules,
        regulations, and laws (collectively, “<strong>NCAA Regulations</strong>
        ”). National Preps is not responsible if you do not abide by NCAA
        Regulations (or any other similar rules or regulations) in connection
        with your use of the National Preps Site, and/or any National Preps
        products or services. If you act in violation of the NCAA Regulations,
        National Preps may take reasonable steps in response, including, but not
        limited to, termination of your access to and use of the National Preps
        Site and/or reporting of such conduct to the NCAA, the authorities, or
        other appropriate entity. National Preps does not knowingly promote any
        violations of NCAA Regulations (or any other similar rules or
        regulations).
      </p>
      <p>
        10.{" "}
        <strong>
          MODIFICATION, SEVERABILITY, WAIVER, ASSIGNMENT, AND ENTIRE AGREEMENT
        </strong>
      </p>
      <p>
        10.1. National Preps reserves the right to make changes to the National
        Preps Site, policies, and these Site Terms at any time. By continuing to
        use or access the National Preps Site after these Site Terms have
        changed, you indicate your agreement to the revised Site Terms. If you
        do not agree to the changes, you should stop using or accessing the
        National Preps Site.
      </p>
      <p>
      10.2. Should any provision of these Site Terms be found unenforceable,
        wherever possible this will not affect any other provision, and each
        will remain in full force and effect. National Preps failure to
        expressly enforce any provision of these Site Terms does not waive its
        rights to enforce that or any other provision.
      </p>
      <p>
      10.3. National Preps may assign, transfer, or delegate these Site Terms
        or any right or obligation or remedy hereunder (in whole or in part) in
        its sole discretion. You may not assign, transfer, or delegate these
        Site Terms or any right or obligation or remedy hereunder (in whole or
        in part), without National Preps prior written consent.
      </p>
      <p>
      10.4. These Site Terms, National Preps Privacy Policy, National Preps
        Acceptable Use Policy, and any other agreement between you and National
        Preps (including your National Preps Agreement, where applicable)
        constitute the sole and entire agreement between you and National Preps
        regarding the National Preps Site and supersede all prior and
        contemporaneous understandings, agreements, representations, and
        warranties, both written and oral, regarding the National Preps Site.
      </p>
      <p>
        11. <strong>NOTICE FOR CALIFORNIA USERS&nbsp;</strong>
      </p>

      <p>
        Under California Civil Code Section 1789.3, California Website users are
        entitled to the following specific consumer rights notice: The Complaint
        Assistance Unit of the Division of Consumer Services of the California
        Department of Consumer Affairs may be contacted in writing at 1625 N.
        Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone
        at (800) 952-5210.
      </p>
      <p>
        12.{" "}
        <strong>
          NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT&nbsp;
        </strong>
        Please send an email to National Preps at <a href="mailto:admin@nationalpreps.com">admin@nationalpreps.com</a> if you believe
        any materials on the National Preps Site infringe your copyright.

      </p>

     
      <p>
        13. <strong>OPERATOR&nbsp;</strong>
      </p>
      <p>
        This website is operated by National Preps, with a business address of 125 Mound Avenue, Excelsior, MN 55331.
      </p>
    </div>
  );
}
