import React, { useState, useEffect } from "react"
import { getHighSchools, deleteRecruit, getInteractionTypes, getStates, getTeams, getRecruit, updateRecruit, addNewActivity, deleteActivity, getRecruitActivitiesWithTeam } from "../../services/supabase";
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import fallBackImage from '../../assets/imgs/np.png'
import { delActivityFromIndex } from "../../services/algoliaActivities";
import { rebuildRecruit } from "../../services/algolia";
import { Drawer, TextField } from '@mui/material';
import AdminDataGrid from "../shared/AdminDataGrid";
import AthleteFiles from "./AthleteFiles";
import { act } from "react";

export default function AthleteEdit(props) {
    const { id } = useParams();

    const [a, setA] = useState({});
    const [interactionTypes, setInteractionTypes] = useState([]);
    const [teams, setTeams] = useState([]);
    const [states, setStates] = useState([]);
    const [interactionTeam, setInteractionTeam] = useState();
    const [selectTeams, setSelectTeams] = useState([]);
    const [highSchools, setHighSchools] = useState([]);
    const [newActivityTeam, setNewActivityTeam] = useState();
    const [newActivityInteractionType, setNewActivityInteractionType] = useState("2");
    const [newActivityDate, setNewActivityDate] = useState(new Date().toISOString().slice(0, 16).replace('T', ' '));
    const [newActivitySource, setNewActivitySource] = useState('Manual Entry');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [activities, setActivities] = useState([]);

    const columns = [
        {
            field: 'ETS',
            headerName: 'ETS',
            flex: 2,
            sortable: true,
            filterable: true,
        },
        {
            field: "School",
            headerName: "Name",
            flex: 3
        },
        {
            field: 'City',
            headerName: 'City',
            sortable: true,
            flex: 2
        },
        {
            field: 'State',
            headerName: 'State',
            sortable: true,
            flex: 2
        },
        {
            field: "County",
            headerName: "County",
            flex: 2
        }
    ];

    const [formData, setFormData] = useState({
        ets: '',
        highSchool: '',
        hsStreet: '',
        hsCity: '',
        hsState: '',
        hsZip: '',
        hsCounty: '',
        hsPhone: '',
        coachname: '',
        coachName: '',
        coachPhone: '',
        coachEmail: '',
        coachTwitter: ''
    });

    async function fetchData() {
        try {
            const res = await getHighSchools();
            setHighSchools(res);
        } catch (error) {
            console.error("Error fetching user list:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchRecruit();
        fetchInteractionTypes();
        fetchTeams();
        fetchStates();
        fetchHighSchools();
        fetchActivities();
    }, [id]);

    const handleRowClick = (params) => {
        setA({ ...a, ets: params.row.ETS })

        a.ets = params.row.ETS;
        a.highSchool = params.row.School;
        a.hsStreet = params.row.Address;
        a.hsCity = params.row.City;
        a.hsState = params.row.State;
        a.hsZip = params.row.Zip;
        a.hsCounty = params.row.County;
        a.hsPhone = params.row.Phone;
        a.coachname = params.row.Coach;
        a.coachName = params.row.Coach;
        a.coachPhone = params.row.Phone;
        a.coachEmail = params.row.Email;
        a.coachTwitter = params.row.Twitter;
        setA(a);
        console.log(a);
        setDrawerOpen(!drawerOpen);
    };

    function handleFormSubmit(e, athlete) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function fetchInteractionTypes() {
        const iTypes = await getInteractionTypes();
        setInteractionTypes(iTypes);
    }

    async function fetchHighSchools() {
        const schools = await getHighSchools();
        setHighSchools(schools);
    }

    async function fetchActivities() {
        const activities = await getRecruitActivitiesWithTeam(id);
        setActivities(activities);
    }

    async function fetchTeams() {
        try {
            const teams = await getTeams();
            const sTeams = teams.map((x) => ({
                value: x.id,
                label: x.name
            }));
            setSelectTeams(sTeams);
            setTeams(teams);
        } catch (error) {
            // Handle any potential errors from the API call
            console.error('Error fetching teams:', error);
        }
    }

    async function fetchStates() {
        const states = await getStates();
        setStates(states);
    }

    async function fetchRecruit() {
        const athlete = await getRecruit(id);
        if (athlete) {
            setA(athlete);
            setFormData({
                ets: athlete.ets,
                highSchool: athlete.highSchool,
                hsStreet: athlete.hsStreet,
                hsCity: athlete.hsCity,
                hsState: athlete.hsState,
                hsZip: athlete.hsZip,
                hsCounty: athlete.hsCounty,
                hsPhone: athlete.hsPhone,
                coachname: athlete.coachname,
                coachName: athlete.coachName,
                coachPhone: athlete.coachPhone,
                coachEmail: athlete.coachEmail,
                coachTwitter: athlete.coachTwitter
            });
        }
        else {
            console.error('Failed to fetch recruit');
            setA({ id: 0 });
        }
    }

    function delActivity(id) {
        const result = deleteActivity(id);

        if (result) {
            delActivityFromIndex(id);
            setA(prevA => ({
                ...prevA,
                Activity: prevA.Activity.filter(activity => activity.id !== id)
            }));
            const newActivities = activities.filter(activity => activity.id !== id);
            setActivities(newActivities);
        } else {
            console.error('Failed to delete activity from database');
        }
    }

    function handleDelete() {
        if (window.confirm("Are you sure you want to delete this athlete?")) {
            deleteRecruit(a.id);
            setIsDeleted(true);
            setA({ id: 0 });
        }
    }

    async function update() {
        const updated = await updateRecruit(a);

        if (updated) {
            const idx = await rebuildRecruit(a.id);
            toast.success("Record Saved", {
                position: "top-right",
                autoClose: 1000,
            });
        }
        else {
            toast.error("There was an issue with the Save: ");
            console.log(updated)
        }
    }

    const lookupHighSchool = () => {
        setDrawerOpen(!drawerOpen);
    };

    function convertToUnixTimestamp(dateString) {
        const date = new Date(dateString);
        return date.getTime();
    }

    const addActivity = async () => {
        const team = teams.find(x => parseInt(x.id) === parseInt(newActivityTeam?.value));

        if (team) {
            const newActivity = {
                "date": convertToUnixTimestamp(newActivityDate),
                "interactionTypeId": newActivityInteractionType,
                "activity_at": new Date(newActivityDate).toISOString().slice(0, 16).replace('T', ' '),
                "teamName": team.name,
                "source": 'Manual Entry',
                "teamId": team.id,
                "logo": team.logo,
                "recruitId": a.id,
                "link": '',
                "detail": 'Manual Entry',
                "division": team.College.Conference.conferenceDivisionId,
                "conference": team.College.Conference.name,
            };

            const activity = await addNewActivity(newActivity);
            if (activity[0]) {
                const updatedAthlete = await updateRecruit(a);
                if (updatedAthlete) {
                    setA(updatedAthlete);
                }

                console.log('Activity added:', activity);

                newActivity.Team = {};
                newActivity.Team.name = team.name;
                newActivity.Team.logo = team.logo;
                newActivity.Team.id = team.id;
                newActivity.id = activity[0].id;

               const newActivities = [...activities, newActivity];
               setActivities(newActivities);
            }
        }
    };

    return (
        <>
            {a && a.id > 0 ?
                <form onSubmit={(e) => handleFormSubmit(e, a)}>
                    <input type="hidden" defaultValue={id} name="id" />

                    <div className="formContainer">
                        <div className="container">
                            <div className="image-container">
                                <img src={a.image ? a.image : fallBackImage} alt={a.lastName} className="profile-image" />
                            </div>
                            <div className="name-container">
                                <h1 className="profile-name">
                                    <strong>{a.firstName} {a.lastName}</strong>
                                </h1>
                            </div>
                            <div className="button-container" style={{ display: 'flex' }}>
                                <div>
                                    <button className="btn btn-danger" onClick={handleDelete}>Delete</button>
                                </div>
                                <div className="ml-5">
                                    <button className="btn btn-success" onClick={() => update()}>Save</button>
                                </div><ToastContainer />
                            </div>
                        </div>

                        {/* Name, Email, Address, Phone */}
                        <div className="form-container mt-3">
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Image Url</strong></div>
                                    <input
                                        type="text"
                                        name="image"
                                        defaultValue={a.image}
                                        placeholder="Image Url"
                                        onChange={(e) => setA({ ...a, image: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Name</strong></div>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            name="firstName"
                                            defaultValue={a.firstName}
                                            placeholder="First Name"
                                            required
                                            onChange={(e) => setA({ ...a, firstName: e.target.value })}
                                        />
                                        <input
                                            className="ml-5"
                                            type="text"
                                            name="lastName"
                                            defaultValue={a.lastName}
                                            placeholder="Last Name"
                                            required
                                            onChange={(e) => setA({ ...a, lastName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Email</strong></div>
                                    <input
                                        type="text"
                                        name="email"
                                        defaultValue={a.email}
                                        placeholder="Email Address"
                                        onChange={(e) => setA({ ...a, email: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Address</strong></div>
                                    <input
                                        type="text"
                                        name="address"
                                        defaultValue={a.athleteAddress}
                                        placeholder="Address"
                                        onChange={(e) => setA({ ...a, athleteAddress: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>City, State, Zip</strong></div>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            name="city"
                                            defaultValue={a.athleteCity}
                                            placeholder="City"
                                            onChange={(e) => setA({ ...a, athleteCity: e.target.value })}
                                        />
                                        <select
                                            name="athleteState"
                                            className="form-select ml-5"
                                            id="athleteState"
                                            value={a.athleteState || ''}
                                            onChange={(e) => setA({ ...a, athleteState: e.target.value })}
                                        >
                                            <option value=""></option>
                                            {states.map((state) => (
                                                <option key={state.stateAbbrev} value={state.stateAbbrev}>
                                                    {state.stateAbbrev}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            name="zip"
                                            defaultValue={a.athleteZip}
                                            placeholder="Zip Code"
                                            className="ml-5"
                                            onChange={(e) => setA({ ...a, athleteZip: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Cell Phone, Home Phone</strong></div>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            name="cellPhone"
                                            defaultValue={a.cellPhone}
                                            placeholder="Cell Phone"
                                            onChange={(e) => setA({ ...a, cellPhone: e.target.value })}
                                        />
                                        <input
                                            className="ml-5"
                                            type="text"
                                            name="homePhone"
                                            defaultValue={a.homePhone}
                                            placeholder="Home Phone"
                                            onChange={(e) => setA({ ...a, homePhone: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-container mt-3">

                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Forty</strong></div>
                                    <input type="text" name="forty" defaultValue={a.forty} onChange={(e) => setA({ ...a, forty: e.target.value })} />
                                </div>
                            </div>
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Vertical Jump</strong></div>
                                    <input type="text" name="verticalJump" defaultValue={a.verticalJump} onChange={(e) => setA({ ...a, verticalJump: e.target.value })} />
                                </div>
                            </div>
                        </div>

                        <div style={{ backgroundColor: '#F2F2F2', display: 'flex', borderRadius: '5px', padding: '30px', marginBottom: '30px' }}>
                            <div className="ml-3 pr-2" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <div className="mb-3">
                                    <div className="form-text"><strong>NP Scout Notes</strong></div>
                                    <TextField
                                        name="scoutComments"
                                        defaultValue={a.scoutComments}
                                        onChange={(e) => setA({ ...a, scoutComments: e.target.value })}
                                        fullWidth
                                        margin="normal"
                                        multiline
                                        rows={6}
                                        style={{ backgroundColor: '#fff', width: '100%' }}

                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-container">
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>GPA</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="gpa"
                                            className="form-select"
                                            id="gpa"
                                            value={a.gpa || ''}
                                            onChange={(e) => setA({ ...a, gpa: e.target.value })}
                                        >
                                            <option value=""></option>
                                            {[...Array((5.0 / 0.1) + 1)].map((_, i) => {
                                                const gpa = (i * 0.1).toFixed(1);
                                                return (
                                                    <option key={gpa} value={gpa}>
                                                        {gpa}
                                                    </option>
                                                );
                                            })}
                                        </select>

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>SAT</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="sat"
                                            className="form-select"
                                            id="sat"
                                            value={a.sat || ''}
                                            onChange={(e) => setA({ ...a, sat: e.target.value })}
                                        >
                                            <option value=""></option>
                                            {[...Array((1600 - 400) / 10 + 1)].map((_, i) => {
                                                const score = 400 + i * 10;
                                                return (
                                                    <option key={score} value={score}>
                                                        {score}
                                                    </option>
                                                );
                                            })}
                                        </select>

                                    </div>
                                </div>
                            </div>

                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>ACT</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="act"
                                            className="form-select"
                                            id="act"
                                            value={a.act || ''}
                                            onChange={(e) => setA({ ...a, act: e.target.value })}
                                        >
                                            <option value=""></option>
                                            {[...Array(36)].map((_, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </div>


                            </div>

                        </div>

                        <div className="form-container">
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Off Position</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="offPosition"
                                            className="form-select"
                                            id="offPosition"
                                            value={a.offPosition || ''}
                                            onChange={(e) => setA({ ...a, offPosition: e.target.value })}
                                        >
                                            <option value=""></option>
                                            <option value="WR">WR</option>
                                            <option value="OL">OL</option>
                                            <option value="RB">RB</option>
                                            <option value="TE">TE</option>
                                            <option value="QB Dual Threat">QB Dual Threat</option>
                                            <option value="QB Pro Style">QB Pro Style</option>
                                            <option value="DB">DB</option>
                                            <option value="DE">DE</option>
                                            <option value="LB">LB</option>
                                            <option value="QB">QB</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Def Position</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="defPosition"
                                            className="form-select"
                                            id="defPosition"
                                            value={a.defPosition || ''}
                                            onChange={(e) => setA({ ...a, defPosition: e.target.value })}
                                        >
                                            <option value=""></option>
                                            <option value="DB">DB</option>
                                            <option value="DL">DL</option>
                                            <option value="LB">LB</option>
                                            <option value="CB">CB</option>
                                            <option value="DE">DE</option>
                                            <option value="DT">DT</option>
                                            <option value="S">S</option>
                                            <option value="TE">TE</option>

                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Scout Position</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="scoutPos"
                                            className="form-select"
                                            id="scoutPos"
                                            value={a.scoutPos || ''}
                                            onChange={(e) => setA({ ...a, scoutPos: e.target.value })}
                                        >
                                            <option value=""></option>
                                            <option value="ATHL">ATHL</option>
                                            <option value="TB">TB</option>
                                            <option value="S">S</option>
                                            <option value="WR O">WR O</option>
                                            <option value="CB">CB</option>
                                            <option value="ILB">ILB</option>
                                            <option value="DE">DE</option>
                                            <option value="OLB">OLB</option>
                                            <option value="WR I">WR I</option>
                                            <option value="OG">OG</option>
                                            <option value="DT">DT</option>
                                            <option value="OT">OT</option>
                                            <option value="QB Pro Style">QB Pro Style</option>
                                            <option value="QB Dual Threat">QB Dual Threat</option>
                                            <option value="TE">TE</option>
                                            <option value="OC">OC</option>
                                            <option value="DB">DB</option>
                                            <option value="K/P">K/P</option>
                                            <option value="K">K</option>
                                            <option value="LS">LS</option>
                                            <option value="FB">FB</option>
                                            <option value="P">P</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Special Teams Position</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="specialTeamsPosition"
                                            className="form-select"
                                            id="specialTeamsPosition"
                                            value={a.specialTeamsPosition || ''}
                                            onChange={(e) => setA({ ...a, specialTeamsPosition: e.target.value })}
                                        >
                                            <option value=""></option>
                                            <option value="LS">LS</option>
                                            <option value="K/P">K/P</option>
                                            <option value="K">K</option>
                                            <option value="P">P</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="form-container">
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>High School</strong></div>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            name="ets"
                                            defaultValue={a.ets}
                                            placeholder="High School ETS"
                                            onChange={(e) => setFormData({ ...formData, ets: e.target.value })}
                                        />&nbsp;
                                        <button
                                            className="btn btn-success"
                                            onClick={lookupHighSchool}
                                        >
                                            Lookup
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <p><strong>{a.highSchool}</strong></p>
                                    <p>{a.hsStreet}<br />{a.hsCity}, {a.hsState} {a.hsZip}<br />{a.hsCounty}<br />{a.hsPhone}</p>
                                    <p><strong>{a.coachName}</strong><br />{a.coachPhone}<br />{a.coachEmail}<br />{a.coachTwitter}</p>
                                </div>
                            </div>
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Jersey, HS Class</strong></div>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            name="jersey"
                                            defaultValue={a.jersey}
                                            placeholder="Jersey Number"
                                            onChange={(e) => setA({ ...a, jersey: e.target.value })}
                                        />
                                        <input
                                            className="ml-5"
                                            type="text"
                                            name="graduatingYear"
                                            defaultValue={a.year}
                                            placeholder="Grad Year"
                                            onChange={(e) => setA({ ...a, year: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Height, Weight</strong></div>
                                    <div className="d-flex align-items-center">
                                        <input
                                            className="short-input"
                                            type="text"
                                            name="height"
                                            defaultValue={a.height}
                                            placeholder="Ft"
                                            onChange={(e) => setA({ ...a, height: e.target.value })}
                                        />
                                        <span className="mx-2">ft</span>
                                        <input
                                            className="short-input"
                                            type="text"
                                            name="weight"
                                            defaultValue={a.weight}
                                            placeholder="Wt"
                                            onChange={(e) => setA({ ...a, weight: e.target.value })}
                                        />
                                        <span className="ml-2">lbs</span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>NP Rating</strong></div>
                                    <div className="d-flex align-items-center">
                                        <select
                                            name="rank"
                                            className="form-select"
                                            id="rank"
                                            value={a.rank || ''}
                                            onChange={(e) => setA({ ...a, rank: e.target.value })}
                                        >
                                            <option value=""></option>
                                            <option value="1 (Elite BCS)">Elite BCS</option>
                                            <option value="2 (Major D1)">Major D1</option>
                                            <option value="3 (FBS / FCS)">FBS / FCS</option>
                                            <option value="4 (FCS / D2)">FCS / D2</option>
                                            <option value="5 (D2 / D3)">D2 / D3</option>
                                            <option value="6 (Early Look)">Early Look</option>
                                            <option value="7 Transfer Portal">Transfer Portal</option>
                                            <option value="Q (Questionnaire)">Questionnaire</option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="form-container">
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>Twitter Handle</strong></div>
                                    <input
                                        type="text"
                                        name="twitter"
                                        defaultValue={a.twitter}
                                        placeholder="Twitter Handle"
                                        onChange={(e) => setA({ ...a, twitter: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Instagram Handle</strong></div>
                                    <input
                                        type="text"
                                        name="instagramHandle"
                                        defaultValue={a.instagram}
                                        placeholder="Instagram Handle"
                                        onChange={(e) => setA({ ...a, instagram: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-column">
                                <div className="mb-3">
                                    <div className="form-text"><strong>NP Showcase</strong></div>
                                    <input
                                        type="text"
                                        name="threadsHandle"
                                        defaultValue={a.threads}
                                        placeholder="NP Showcase"
                                        onChange={(e) => setA({ ...a, threads: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <div className="form-text"><strong>Hudl Url</strong></div>
                                    <input
                                        type="text"
                                        name="hudlUrl"
                                        defaultValue={a.hudl}
                                        placeholder="Hudl Url"
                                        onChange={(e) => setA({ ...a, hudl: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-container">
                            <AthleteFiles id={a.id} isAdmin={true} />
                        </div>

                        <div style={{ backgroundColor: '#F2F2F2', display: 'flex', borderRadius: '5px', padding: '30px' }}>

                            <div className="ml-3 pr-2" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <table className="table table-hover" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th className="text-left">Logo</th>
                                            <th className="text-left">Team</th>
                                            <th className="text-left">Type</th>
                                            <th className="text-left">Date</th>
                                            <th className="text-left">Source</th>
                                            <th className="text-left">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {activities?.map((activity, idx) => {
                                            const intType = interactionTypes.find(x => x.id === parseInt(activity.interactionTypeId))
                                            return (
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <img src={activity.Team?.logo} style={{ width: '50px' }} />
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {activity.Team?.name}
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {intType?.interactionType}
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {activity?.activity_at}
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {activity.link && activity.link != '' ? (<a target="_blank" href={activity?.link}>{activity?.source}</a>) : (activity?.source)}
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <button
                                                            className="btn btn-warning"
                                                            onClick={() => delActivity(activity.id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <hr style={{ paddingTop: '10px', paddingBottom: '10px' }} />

                                <table className="table" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>

                                            <td style={{ verticalAlign: 'middle' }}>

                                                {selectTeams.length > 0 ? (
                                                    <Select
                                                        id="addTeam"
                                                        isSearchable={true}
                                                        name="addTeam"
                                                        options={selectTeams}
                                                        value={selectTeams.find(option => option.value === interactionTeam?.value)} // Set the value to the matched option in selectTeams
                                                        onChange={(selectedOption) => setNewActivityTeam(selectedOption)}
                                                    />
                                                ) : (
                                                    <p>Loading teams...</p> // Or any other placeholder until selectTeams is populated
                                                )}

                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <select
                                                    className="form-select"
                                                    id="newActivityInteractionType"
                                                    defaultValue={newActivityInteractionType}
                                                    required
                                                    onChange={(e) => setNewActivityInteractionType(e.target.value)}
                                                >
                                                    {interactionTypes.map((interaction) => (
                                                        <option key={interaction.id} value={interaction.id}>
                                                            {interaction.interactionType}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>

                                            <td style={{ verticalAlign: 'middle' }}>
                                                <input type="text" name="newActivityDate" id="newActivityDate" onChange={(e) => setNewActivityDate(e.value)} defaultValue={newActivityDate} placeholder="Date" />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <input type="text" name="addSource" id="addSource" onChange={(e) => setNewActivitySource(e.value)} defaultValue={newActivitySource} placeholder="Source Link" />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <button className="btn btn-primary" onClick={addActivity}>
                                                    Add
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div >
                </form>
                : (
                    <div>{isDeleted ? 'Athlete Deleted' : 'Athlete Does not exist'}</div>
                )}

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                sx={{ width: '400px' }}
            >
                <div style={{ padding: '20px' }}>
                    <AdminDataGrid data={highSchools} columns={columns} onRowClick={handleRowClick} />
                </div>
            </Drawer>
        </>
    )

}